import React from 'react';
import { classNames } from '@/lib/utils';

export default function LoadingSpinner({ className = 'text-white' }: { className?: string }) {
  const classes = classNames('animate-spin my-auto -ml-1 mr-3 h-5 w-5', className);
  return (
    // <svg className={classes} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    //   <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
    //   <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
    // </svg>

    <svg
      className={classes}
      width="24"
      height="24"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="spinnerGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: '#7549F4', stopOpacity: 1 }} />
          <stop offset="50%" style={{ stopColor: '#F94DDB', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#F3CA9E', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <g className="spinner_V8m1">
        <circle
          cx="12"
          cy="12"
          r="9.5"
          fill="none"
          stroke="url(#spinnerGradient)"
          strokeWidth="3"
        ></circle>
      </g>
    </svg>

  );
}

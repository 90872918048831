import { useState } from 'react';
import { PropsOf } from '@headlessui/react/dist/types';
import Anchor from 'shared-ui/components/Anchor';
// FIXME: The Button component is expensive (because of the Tooltip component inside it)
import { Button } from '../Button';

export const Email = ({
  onSubmit,
  view,
  isLoading,
  successMessage,
  label = 'Email address*',
  withMaintenanceMode,
  defaultValue,
}: {
  onSubmit: (email: string) => void;
  view: 'sign-in' | 'sign-up' | 'update-email' | 'forgot-password';
  isLoading: boolean;
  successMessage?: string;
  label?: string;
  defaultValue?: string;
} & Pick<PropsOf<typeof Button>, 'withMaintenanceMode'>) => {
  const [email, setEmail] = useState<string>(defaultValue ?? '');

  // const buttonLabelText = useMemo(() => {
  //   switch (view) {
  //     case 'sign-in':
  //       return 'Login';
  //     case 'sign-up':
  //       return 'Sign up';
  //     case 'update-email':
  //       return 'Update Email';
  //     case 'forgot-password':
  //       return 'Reset password';
  //   }
  // }, [view]);

  return (
    <form onSubmit={(event) => {
      event.preventDefault();
      onSubmit(email);
    }}
    >
      <div className="space-y-4">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700" htmlFor="email">
            {label}
          </label>
          <div className="relative mt-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
              <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
            </div>
            <input
              id={`${view}-email`}
              name="email"
              type="email"
              placeholder="Enter your email"
              disabled={isLoading}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              autoComplete={'email'}
              autoFocus
              required
              className="shadow-xs block w-full flex-1 rounded-[11px] border-gray-300 py-2.5 px-4 pl-12 text-[1.03rem] 
              ring-1 !focus:ring-0 border-transparent border-0 focus:ring-offset-[-1px] focus:shadow-[0_0px_0px_3px_rgba(110,63,243,0.12)] focus:border-[#7549f4] focus:ring-[#7549f4] ring-neutral-200 placeholder-neutral-400"
            />
          </div>
        </div>
        {view === 'forgot-password' ? (
          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Anchor href="/login" className="font-medium text-zinc-500 hover:text-zinc-700">
                Log in instead?
              </Anchor>
            </div>
          </div>
        ) : null}
        <div>
          {isLoading ? (
            <Button
              disabled
              type="submit"
              authButton={true}
              withMaintenanceMode={withMaintenanceMode}
              className="flex justify-center rounded-full w-full text-shadow px-4 py-3.5 text-md font-medium text-white bg-yellow-300 border border-transparent shadow-sm cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              style={{
                background: `linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(275.25deg, #460EE7 0.81%, #7C52F5 54.05%, #460EE7 99.4%)`,
                boxShadow: `0px 1px 2px rgba(47, 10, 154, 0.4), 0px 0px 0px 1px rgba(117, 73, 244, 0.8)`,
              }}
            >
              Loading...
            </Button>
          ) : (
            <Button
              type="submit"
              withMaintenanceMode={withMaintenanceMode}
                authButton={true}
                className="flex justify-center rounded-full w-full text-shadow px-4 py-3.5 text-md font-medium text-white border border-transparent shadow-sm focus:outline-none focus:ring-2"
                style={{
                  background: `linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(275.25deg, #460EE7 0.81%, #7C52F5 54.05%, #460EE7 99.4%)`,
                  boxShadow: `0px 1px 2px rgba(47, 10, 154, 0.4), 0px 0px 0px 1px rgba(117, 73, 244, 0.8)`,
                }}
            >
              Send magic link
            </Button>
          )}
        </div>
        <div>
          {successMessage ? (
            <p className="text-sm text-center text-green-500">
              {successMessage}
            </p>
          ) : null}
        </div>
      </div>
    </form>
  );
};

"use client"
import { RenderProviders } from '@/components/presentational/tailwind/Auth/RenderProviders';
import { Email } from '@/components/presentational/tailwind/Auth/Email';
import { EmailAndPassword } from '@/components/presentational/tailwind/Auth/EmailAndPassword';
import { useSignInWithMagicLink, useSignInWithPassword, useSignInWithProvider } from '@/utils/react-query-hooks';
import React, { useState } from 'react';
import { APP_DOMAIN } from '@/constants';
import Anchor from 'shared-ui/components/Anchor';
import { LoadingSpinner } from '@/components/presentational/tailwind/LoadingSpinner';

export function Login() {

    const [isSuccessful, setIsSuccessful] = useState(false);
    function redirectToDashboard() {
        setIsSuccessful(true);
        window.location.href = `${process.env.NODE_ENV == 'production' ? 'https://' : 'http://'}${APP_DOMAIN}`;
    }
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showMagicLink, setShowMagicLink] = useState<boolean>(false);

    // Google Login
    const providerMutation = useSignInWithProvider();
    // Email and Password Login
    const passwordMutation = useSignInWithPassword({
        onSuccess: redirectToDashboard,
        onError: (error: any) => {
            setErrorMessage(error.message);
        }
    });
    // Magic Link Login
    const magicLinkMutation = useSignInWithMagicLink({
        onSuccess: () => {
            setSuccessMessage('A magic link has been sent to your email!');
        },
        onError: (error: any) => {
            setErrorMessage(error.message);
        },
    });

    return (
        <div className="container grid items-center h-full max-w-lg mx-auto overflow-auto overflow-visible">
            {isSuccessful ? (
                <div className='items-center mx-auto mt-10 text-center'>
                    <LoadingSpinner className="w-8 h-8 !mx-auto text-purple-500" />
                    <p className="mt-3 font-medium text-gray-800 text-md">Logging you in ...</p>
                </div>
            ) : (
                <div className="space-y-8 ">
                    {/* <Auth providers={['twitter']} supabaseClient={supabase} /> */}
                    <div className="space-y-2 ">
                            <h1 className="mt-10 mb-3 text-2xl font-semibold text-center text-slate-900 sm:text-3xl lg:mt-5 xl:mt-7 xl:text-2xl">
                            Log back in
                        </h1>
                            <p className="max-w-sm mx-auto text-sm leading-6 text-center text-gray-500">
                                Create your free account on Keesy to start growing your Instagram today.
                        </p>
                    </div>
                    {errorMessage && (
                            <div className="relative animate-fade-in-up-short px-4 py-2.5 border-1 rounded-[10px]" role="alert" style={{ background: 'rgba(223, 28, 65, 0.08)', color: '#DF1C41', borderColor: 'rgba(223, 28, 65, 0.08)' }}>
                                <svg className="inline-flex mr-2.5 align-middle" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 6.00003V8.6667M8 11.3334H8.00667M7.07688 2.59451L1.59362 12.0656C1.28948 12.5909 1.13742 12.8536 1.15989 13.0692C1.1795 13.2572 1.27801 13.4281 1.43091 13.5392C1.60622 13.6667 1.90973 13.6667 2.51674 13.6667H13.4833C14.0903 13.6667 14.3938 13.6667 14.5691 13.5392C14.722 13.4281 14.8205 13.2572 14.8401 13.0692C14.8626 12.8536 14.7105 12.5909 14.4064 12.0656L8.92312 2.59451C8.62007 2.07107 8.46855 1.80935 8.27087 1.72145C8.09843 1.64477 7.90157 1.64477 7.72913 1.72145C7.53145 1.80935 7.37992 2.07107 7.07688 2.59451Z" stroke="#DF1C41" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="block font-medium align-middle sm:inline">{errorMessage}</span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                    <svg className="w-5 h-5 align-middle fill-current" width="16" height="16" role="button" viewBox="0 0 16 16" fill="none" onClick={() => setErrorMessage(null)} xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.8">
                                            <path d="M11.3332 4.66663L4.6665 11.3333M4.6665 4.66663L11.3332 11.3333" stroke="#DF1C41" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                    </svg>
                            </span>
                        </div>
                    )}

                    {showMagicLink ? (
                        <Email successMessage={successMessage} isLoading={magicLinkMutation.isLoading} view="sign-in" onSubmit={(email) => {
                            magicLinkMutation.mutate({ email });
                        }}
                        />
                    ) : (
                        <>
                            <RenderProviders
                                providers={['google']}
                                isLoading={providerMutation.isLoading}
                                onProviderLoginRequested={(provider) => {
                                    providerMutation.mutate({
                                        provider,
                                    });
                                }}
                                isLogin={true}
                            />

                            <EmailAndPassword
                                isLoading={passwordMutation.isLoading}
                                onSubmit={(data) => {
                                    passwordMutation.mutate(data);
                                }}
                                view="sign-in"
                            />
                        </>
                    )}

                    {/* Magic link option*/}
                        <p className="w-full mt-10 text-sm font-medium text-center text-zinc-500 lg:mt-20 xl:mt-32">
                        Or log in {' '}
                        {!showMagicLink ? (
                                <button className="text-zinc-900 hover:underline focus:outline-none" onClick={() => setShowMagicLink(true)}>
                                    using a magic link <span className="pl-1 font-bold">→</span>
                            </button>
                        ) : (
                                    <button className="text-zinc-900 hover:underline focus:outline-none" onClick={() => setShowMagicLink(false)}>
                                        using email and password <span className="pl-1 font-bold">→</span>
                            </button>
                        )}
                    </p>
                        <hr className="my-8 border-dashed" />

                    {/* Sign up option */}
                        <p className="w-full pb-1 mt-10 text-sm font-medium text-center text-zinc-500 lg:mt-20 xl:mt-32">
                        Don't have an account?{' '}
                        <Anchor
                            href="/sign-up"
                            title="Sign Up"
                                className="hover:underline text-zinc-900 focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                        >
                            Get started for free
                        </Anchor>
                    </p>

                </div>
            )}
        </div>
    );
}
